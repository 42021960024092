import { IncomingMessagesKeys } from '../constants';

export const defaultPluginEvents = {
  DESTROY: 'DESTROY',
  STATE_CHANGED: 'STATE_CHANGED',
  LOADING_STATE_CHANGED: 'LOADING_STATE_CHANGED',
  PARAMETERS_CHANGED: 'PARAMETERS_CHANGED',
  PLUGIN_AVAILABILITY_CHANGED: 'PLUGIN_AVAILABILITY_CHANGED',
};

export const globalEventsKeys = {
  MODEL_LOADED: 'modelLoaded',
  MODEL_UNLOADED: 'MODEL_UNLOADED',
  AFTER_MODEL_UNLOAD_COMPLETTE: 'AFTER_MODEL_UNLOAD_COMPLETTE',
  MODEL_LOAD_FAIL: 'MODEL_LOAD_FAIL',
  APP_LOADED: 'appLoaded',
  NIRI_LOADED: 'niriLoaded',
  NIRI_PROGRESS_LOADING_CHANGED: 'NIRI_PROGRESS_LOADING_CHANGED',
  MODEL_CHANGED: 'modelChanged',
  LAYOUT_CHANGED: 'layoutChanged',
  POINTER_MOVE: 'pointerMove',
  CAMERA_CHANGED: 'cameraChanged',
  CLICK_CANVAS: 'clickCanvas',
  CAMERA_STOPPED_MOVING: 'cameraStoppedMoving',
  TAP_2_FINGERS: 'tap2Fingers',
  DOUBLE_TAP_2_FINGERS: 'doubleTap2Fingers',
  CAMERA_FREEZE: 'cameraFreeze',
  CAMERA_UNFREEZE: 'cameraUnFreeze',
  POINTER_START: 'pointerStart',
  POINTER_STOP: 'pointerStop',
  TEXTURE_CHANGED: 'textureChanged',
  METADATA_CHANGED: 'metadataChanged',
  VISIBILITY_CHANGED: 'visibilityChanged',
  JAWS_CHANGED: 'jawsChanged',
  SELECT_POINT: 'selectPoint',
  RESIZING: 'resizing',
  MULTIBITE_CHANGED: 'MULTIBITE_CHANGED',
  RESET_RENDERING_STAGE: 'RESET_RENDERING_STAGE',
  JAWS_TOGGLE_MODE_CHANGED: 'JAWS_TOGGLE_MODE_CHANGED',
  OUT_OF_FOCUS: 'OUT_OF_FOCUS',
  MODEL_COMPARE_INITIATED: 'MODEL_COMPARE_INITIATED',
  CONDITION_NAVIGATION_CHANGED: 'CONDITION_NAVIGATION_CHANGED',
  SPLITTED_VIEW_WITH_SIDE_PLUGIN_CHANGED: 'SPLITTED_VIEW_WITH_SIDE_PLUGIN_CHANGED',
  DENTAL_XRAI_DATA_RECIVED: 'DENTAL_XRAI_DATA_RECIVED',
  RAISE_POLL_REQUEST: 'RAISE_POLL_REQUEST',
  POLL_REQUEST_RESPONSE: 'POLL_REQUEST_RESPONSE',
  POLL_REQUEST_RESPONSE_ERROR: 'POLL_REQUEST_RESPONSE_ERROR',
  LUMINA_INITIALIZE_COMPLETTE: 'LUMINA_INITIALIZE_COMPLETTE',
  REFRESH_GRID_CALCULATION_COMPLETTE: 'REFRESH_GRID_CALCULATION_COMPLETTE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  API_MAPPING_CHANGED: 'API_MAPPING_CHANGED',
  ANIMATION_FINISH: 'ANIMATION_FINISH',
};

export const toolsEvents = {
  DRAGGABLE_OBJECT: {
    ON_START: 'draggable_object.onStart',
    ON_DRAG: 'draggable_object.onDrag',
    ON_STOP: 'draggable_object.onStop',
  },
  EVENT_ORIGINS: {
    LOUPE_DRAG: 'loupe_drag',
    MODEL_ROTATION: 'model_rotation',
  },

  LOUPE: {
    ON_CLICK: 'loupe_click',
  },
};

export const hostEvents = { ...IncomingMessagesKeys };

export const hostEventsErrors = {
  DATA_IS_NOT_VALID: 'DATA_IS_NOT_VALID',
};
