import { once } from 'lodash';
import { Timber } from '@itero/timber';
import { LogLevel, EventType, AppenderType } from '@itero/timber/enums';
import logger from '../logger/logger';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
import { appSettingsManager } from '../app-settings-manager';
import { utils } from '../utils';
let timber = null;
let biTimber = null;
let runOnce = false;
let runOnceBI = false;

export const missingTimberUrlParamError = 'Missing timber url for timberLogger';
const appId = 'web-3d-tool-poi';

const getTimberInstance = () => {
  if (runOnce) {
    return timber;
  }

  const timberUrl = settingsManager.getConfigValue(configuration.timberUrl) || null;
  const companyId = settingsManager.getConfigValue(configuration.companyId) || 'NO_COMPANY_ID_PARAM';
  const userId = settingsManager.getConfigValue(configuration.userId) || 'NO_USER_ID_PARAM';

  if (!timberUrl) {
    console.error(missingTimberUrlParamError);
    runOnce = true;
    return null;
  }

  runOnce = true;

  timber = new Timber({
    appId,
    appenderTypes: [AppenderType.Remote],
    companyId,
    userId,
    minLogLevel: LogLevel.All,
    requiredFields: [],
    url: timberUrl,
    eventType: EventType.BusinessReport,
  });

  return timber;
};

const getBITimberInstance = async () => {
  try {
    if (runOnceBI) {
      return biTimber;
    }

    const { biLoggingEndPoint } = appSettingsManager.getAppSettingsByValue('environmentParametersSettings');
    const biTimberUrl = biLoggingEndPoint || null;
    const companyId = settingsManager.getConfigValue(configuration.companyId) || 'NO_COMPANY_ID_PARAM';
    const userId = settingsManager.getConfigValue(configuration.userId) || 'NO_USER_ID_PARAM';

    if (!biTimberUrl) {
      console.error(missingTimberUrlParamError);
      runOnceBI = true;
      return null;
    }

    runOnceBI = true;

    biTimber = new Timber({
      appId,
      appenderTypes: [AppenderType.BIRemote],
      companyId,
      userId,
      minLogLevel: LogLevel.All,
      requiredFields: [],
      url: biTimberUrl,
    });

    return biTimber;
  } catch (error) {
    return biTimber;
  }
};

const buildBITimberMessage = (params) => {
  const { additionalFields, biEventType } = params;
  const message = {
    component: settingsManager.getConfigValue(configuration.POIEnabled) === 'true' ? 'web-3d-tool-poi' : 'Web Viewer',
    sessionId: settingsManager.getConfigValue(configuration.SessionId) || null,
    sessionType: settingsManager.getConfigValue(configuration.SessionType) || null,
    type: biEventType,
    additionalFields: additionalFields,
  };
  return message;
};

const buildTimberMessage = (params) => {
  const { eventName, action, module, type, actor = 'User', value } = params;
  const platform = utils.getEnv() || 'eup';
  const message = {
    eventName,
    module,
    type,
    actor,
    action,
    value: { value: value },
    platform,
  };
  return message;
};

export const logToTimber = ({ timberData }) => {
  const timber = getTimberInstance();
  logger
    .info(timberData.eventName)
    .data({ module: timberData.module })
    .to(['analytics', 'host'])
    .end();
  if (timber) {
    const message = buildTimberMessage(timberData);
    timber.log(LogLevel.All, {}, { extendedParameters: message });
  }
};

export const getTimberUrls = once(async () => {
  const timberUrls = [];
  const timberBiInstance = await getBITimberInstance();
  const timberInstance = await getTimberInstance();

  timberInstance && timberUrls.push(timberInstance.config.url);
  timberBiInstance && timberUrls.push(timberBiInstance.config.url);

  return timberUrls;
});

/**
 * @param {Object.<function>} biMethods - accepts any function from biMethods :
 * - userActionBiLog: (function).
 * - uiNavigationBiLog: (function).
 * - errorReportBiLog: (function).
 * - apiReportBiLog: (function).
 * - specialBiLog: (function).
 * @returns {void} logging BI to Timber.
 */
export const logToTimberBI = async ({ timberData }) => {
  const timber = await getBITimberInstance();
  logger
    .info(timberData.eventName)
    .data({ module: timberData.module })
    .to(['analytics', 'host'])
    .end();
  if (timber) {
    const message = buildBITimberMessage(timberData);
    if (!!message.sessionId && !!message.sessionType) {
      timber.biLog(LogLevel.All, {}, { extendedParameters: message });
    }
  }
};
