import React from 'react';
import PropTypes from 'prop-types';

import styles from './CompareWrapper.module.css';

const CompareWrapper = (props) => {
  const { children, dismiss } = props;

  return (
    <div onClick={dismiss} className={styles.compareWrapper} id="compare-wrapper">
      {children}
    </div>
  );
};

CompareWrapper.propTypes = {
  /**
   * Element of children that modal contains.
   */
  children: PropTypes.element,
};

CompareWrapper.defaultProps = {};

export default CompareWrapper;
