import React, { useState } from 'react';
import classNames from 'classnames';
import { eventBus } from '@web-3d-tool/shared-logic/src';
import { toolsEvents } from '@web-3d-tool/shared-logic';
import styles from './HtmlLoupe.module.css';

const HtmlLoupe = (props) => {
  const [mouseMoveState, setMouseMove] = useState({ mouseMove: false, skip: false });
  const [inFocus, setFocusStatus] = useState(true);
  const { shadowEnabled = true } = props;
  const loupeStyle = classNames([styles.loupe, shadowEnabled ? styles.loupeShadow : '']);
  const loupeHandleStyle = classNames([styles.loupeHandle, shadowEnabled ? styles.loupeHandleShadow : '']);

  const mouseDown = (e) => {
    setMouseMove({ mouseMove: false, skip: !inFocus });
  };

  const mouseUp = (e) => {
    const { mouseMove } = mouseMoveState;
    if (!mouseMove) {
      eventBus.raiseEvent(toolsEvents.LOUPE.ON_CLICK, { x: e.clientX, y: e.clientY });
    }
  };

  const mouseMove = (e) => {
    const { skip } = mouseMoveState;
    setFocusStatus(document.hasFocus());
    if (!skip || inFocus) {
      setMouseMove({ ...mouseMoveState, mouseMove: true });
    }
  };

  return (
    <div
      className={loupeStyle}
      onMouseMove={mouseMove}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      data-test-id="loupe"
      id="loupe"
    >
      <div className={loupeHandleStyle}>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default HtmlLoupe;
