import * as THREE from 'three';
const POI = 'POI';
const activePOI = 'activePOI';
const flagsMap = new Map();
const materialMap = new Map();
const pointColor = '#2AD2C9';
const pointStrokeStyle = '#22a8a1';
const activePoint = '#ffff25';
const poi3D = 'poi3D';

export const createPOISprite = (poiData, jaw) => {
  if (!poiData || !poiData[jaw][poi3D].length) return null;
  if (flagsMap.has(jaw)) return flagsMap.get(jaw);
  createCariesMaterial();
  return createThreeGroup(poiData, jaw);
};

const createThreeGroup = (poiData, jaw) => {
  const flags = poiData[jaw][poi3D].map((data) => {
    const point = new THREE.Vector3(data.x, data.y, data.z);
    const group = new THREE.Group();
    createSprites(group, point, jaw, data.id);
    return group;
  });
  flagsMap.set(jaw, flags);
  return flags;
};

const createCariesMaterial = () => {
  if (!materialMap.has(POI)) {
    materialMap.set(POI, getMaterial(POI));
  }

  if (!materialMap.has(activePOI)) {
    materialMap.set(activePOI, getMaterial(activePOI));
  }
};

const createSprites = (group, point, jaw, flagId) => {
  const sprite = getSprite(POI);
  const activeSprite = getSprite(activePOI);
  sprite.position.copy(point);
  activeSprite.position.copy(point);
  setMetadata(sprite, 'pointOfInterest', true, jaw, flagId);
  setMetadata(activeSprite, 'selectedPointOfInterest', false, jaw, flagId);
  group.add(activeSprite).add(sprite);
};

const setMetadata = (sprite, name, visible, jaw, flagId) => {
  Object.assign(sprite, {
    userData: { flagId, jaw },
    name,
    visible,
  });
};

const getSprite = (materialKey) => {
  const materialSprite = materialMap.get(materialKey);
  const sprite = new THREE.Sprite(materialSprite);
  sprite.scale.set(2.6, 2.6, 2.6);
  return sprite;
};

const getMaterial = (key) => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 256;
  const ctx = canvas.getContext('2d');
  key === POI ? drawSmallCircle(ctx) : drawTwoCircles(ctx);
  const map = new THREE.Texture(canvas);
  map.needsUpdate = true;
  const materialSprite = new THREE.SpriteMaterial({
    map: map,
    sizeAttenuation: false,
    depthTest: false,
    depthWrite: false,
  });
  return materialSprite;
};

const drawTwoCircles = (context) => {
  context.beginPath();
  context.arc(128, 128, 128, 0, 2 * Math.PI);
  context.fillStyle = activePoint;
  context.fill();
  drawSmallCircle(context);
};

const drawSmallCircle = (context) => {
  context.beginPath();
  context.arc(128, 128, 70, 0, 2 * Math.PI);
  context.fillStyle = pointColor;
  context.fill();
  context.lineWidth = 20;
  context.strokeStyle = pointStrokeStyle;
  context.stroke();
};
