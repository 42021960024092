import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { logValueChangesUsingTimber } from './timberWrapper';
import ImageFrame from '../ImageFrame';
import ImageManipulationSlider from '../ImageManipulationSlider';
import styles from './ImageFrameManipulation.module.css';
import brightnessIcon from './assets/brightness.png';
import contrastIcon from './assets/contrast.png';
import ImageMarking from '../ImageMarking';

const ImageFrameManipulation = ({
  width,
  height,
  src,
  rotation,
  top,
  left,
  scale,
  onChange,
  isVertical,
  markingMode,
  onMarkerCreated,
  componentToRender,
  id,
  dataBiType,
  defaultBrightness,
  defaultContrast,
  onBrightnessChanged,
  onContrastChanged,
  is360,
  selectedPointOnImage,
  originalImageSize,
  isEnlargedFrame,
  sdk,
  url,
  isCariesPluginActive,
}) => {
  const CONNTRAST_MIN_VALUE = 0;
  const CONNTRAST_MAX_VALUE = 200;

  const [brightness, setBrightness] = useState(defaultBrightness);
  const [contrast, setContrast] = useState(defaultContrast);
  const [brighnessPrevValue, setBrighnessPrevValue] = useState(100);
  const [contrastPrevValue, setContrastPrevValue] = useState(100);

  const handleBrightnessBeforeChange = (value) => {
    setBrighnessPrevValue(value);
  };

  const handleBrightnessChange = (value) => {
    onBrightnessChanged(value);
    setBrightness(value);
  };

  const handleBrightnessAfterChange = (value) => {
    logValueChangesUsingTimber({
      changeType: 'brightness',
      value,
      dataBiType,
    });
  };

  const handleContrastChange = (value) => {
    onContrastChanged(value);
    setContrast(value);
  };

  const handleBeforeContrastChange = (value) => {
    setContrastPrevValue(value);
  };

  const handleAfterContrastChange = (value) => {
    logValueChangesUsingTimber({
      changeType: 'contrast',
      value,
      dataBiType,
    });
  };

  return (
    <div className={styles.container} style={{ flexDirection: isVertical ? 'row' : 'column-reverse' }}>
      <div
        className={styles.sliders}
        style={{
          flexDirection: isVertical ? 'column' : 'row',
          width: isVertical ? 'auto' : width,
          height: isVertical ? 'auto' : '33px',
        }}
      >
        <ImageManipulationSlider
          value={brightness}
          onChange={handleBrightnessChange}
          onBeforeChange={handleBrightnessBeforeChange}
          onAfterChange={handleBrightnessAfterChange}
          defaultValue={defaultBrightness}
          icon={brightnessIcon}
          isVertical={isVertical}
          dataTestId={`${dataBiType}-brightness-slider`}
        />
        <ImageManipulationSlider
          value={contrast}
          onChange={handleContrastChange}
          onBeforeChange={handleBeforeContrastChange}
          onAfterChange={handleAfterContrastChange}
          defaultValue={defaultContrast}
          min={CONNTRAST_MIN_VALUE}
          max={CONNTRAST_MAX_VALUE}
          icon={contrastIcon}
          isVertical={isVertical}
          dataTestId={`${dataBiType}-contrast-slider`}
        />
      </div>
      {markingMode ? (
        <ImageMarking
          width={width}
          height={height}
          src={src}
          brightness={brightness}
          contrast={contrast}
          onMarkerCreated={onMarkerCreated}
        />
      ) : (
        <ImageFrame
          is360={is360}
          width={width}
          height={height}
          src={src}
          brightness={brightness}
          contrast={contrast}
          rotation={rotation}
          selectedPointOnImage={selectedPointOnImage}
          originalImageSize={originalImageSize}
          onChange={onChange}
          scale={scale}
          top={top}
          left={left}
          id={id}
          dataBiType={dataBiType}
          isEnlargedFrame={isEnlargedFrame}
          url={url}
          sdk={sdk}
          isCariesPluginActive={isCariesPluginActive}
        />
      )}
      {componentToRender}
    </div>
  );
};

ImageFrameManipulation.defaultProps = {
  defaultContrast: 100,
  defaultBrightness: 100,
  isVertical: true,
  markingMode: false,
  onMarkerCreated: noop,
  onBrightnessChanged: noop,
  onContrastChanged: noop,
};

ImageFrameManipulation.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image height
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The rotation to present the images
   */
  rotation: PropTypes.number,
  /**
   * Set the position of the Sliders to below ImageFrame
   */
  isVertical: PropTypes.bool,
  /**
   * set the images to marking mode if true
   */
  markingMode: PropTypes.bool,
  /**
   * Callback, fired when pinch, zoom, pan changes
   */
  onChange: PropTypes.func,
  /**
   *when a new marker is created
   */
  onMarkerCreated: PropTypes.func,
  /**
   * Image id
   */
  id: PropTypes.string.isRequired,

  /**
   *  defaultBrightnesss
   */
  defaultBrightnesss: PropTypes.number,

  /**
   *  defaultContrast
   */
  defaultContrast: PropTypes.number,
  /**
   *  onBrightnessChanged
   */
  onBrightnessChanged: PropTypes.func,
  /**
   *  onContrastChanged
   */
  onContrastChanged: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool,
  /**
   * sdk object
   */
  sdk: PropTypes.object,
  /**
   * Image url
   */
  url: PropTypes.string,
};

export default ImageFrameManipulation;
