import { template, endsWith, get } from 'lodash';
import fetchProgress from 'fetch-progress';
import requestsCacheManager from '../api-requests/download-file/requests-cache-manager';
import { logToTimber, logToTimberBI, biMethods } from '../timberLogger';
import { map } from './apiMap';
import { ScannerEnvironmentURLs } from '../constants/environment.constants';
import { settingsManager } from '../settings-manager';
import * as configuration from '../constants/configurationValues.constants';
import { utils } from '../utils';
import logger from '../logger';
import request from './apiService';
import { apiHeaders } from '../constants/apiEnums';
import { featureAvaliability } from '../feature-avaliability/feature-avaliability-service';
import { appSettingsManager } from '../app-settings-manager';

const { apiMap } = map;
const { Content_Type } = apiHeaders;
const logToGAAndTimber = ({
  url,
  options = {},
  isForced = false,
  howRequestWasProcessed,
  errorMessage = null,
  module,
  biResultOptions = {},
}) => {
  const fileType = options.fileType;
  const metaData = {
    url,
    orderId: settingsManager.getConfigValue(configuration.orderId),
    options,
    isForced,
    howRequestWasProcessed,
  };

  if (errorMessage) {
    metaData.errorMessage = errorMessage;
  }

  logger
    .info('Network')
    .to(['analytics', 'host'])
    .data({ fileType, ...metaData, module: module })
    .end();

  logToTimber({
    timberData: {
      action: `api call: ${url}`,
      module: module,
      type: 'object',
      actor: 'System',
      value: metaData,
    },
  });

  logToTimberBI(
    biMethods.apiReportBiLog({
      name: module,
      action: 'GET',
      url: url,
      parameters: '',
      caller: '',
      result: {
        options: biResultOptions,
        status: howRequestWasProcessed,
      },
    })
  );
};

const handleDownloadFileRequest = async (response) => {
  let contentLength = 0;
  const isStandaloneMode = utils.isStandaloneMode();

  if (isStandaloneMode) {
    // because in standalone mode we are not getting 'content-length' header from API we need to check if file has content.
    const clone = response.clone();
    const blob = await clone.blob();
    contentLength = parseFloat((blob.size / 1024 / 1024).toFixed(2), 10);
  }

  const modelSize = parseFloat(response.headers.get('content-length') || contentLength, 10) / 1024 / 1024;
  if (modelSize == null || isNaN(modelSize)) {
    throw new Error('model size error');
  }

  return modelSize;
};

const handleRequest = async (props) => {
  const {
    url,
    selector,
    type = 'api-call',
    options = {},
    force = false,
    progressCB = () => {},
    module,
    ...other
  } = props;

  let timberLoggingProps = {};

  try {
    if (!force) {
      const { queryParams } = other;
      const { type: fileType, orderId } = queryParams || {};
      const urlInCache =
        url ||
        Object.keys(requestsCacheManager.getCache()).find((url) =>
          url.includes(fileType ? `${selector}?type=${fileType}&orderId=${orderId}` : `${selector}`)
        );
      const cachedResponse = requestsCacheManager.checkIfExistsInCache(urlInCache);
      if (cachedResponse && !get(cachedResponse, 'body.locked')) {
        timberLoggingProps = { url: urlInCache, options, isForced: force, howRequestWasProcessed: 'Succeeded', module };
        return cachedResponse;
      }
    }
    logger.time(`request: ${url || selector}`);

    let response = await request({ ...props, ...other, options });
    const requestUrl = response.url;

    const progressInterceptor = fetchProgress({ onProgress: (progressData) => progressCB(progressData.percentage) });
    const result = await progressInterceptor(response);
    const timeToDownloadMs = logger.timeEnd(`request: ${requestUrl}`, { module: module });
    const isSuccess = result && result.status && result.status >= 200 && result.status < 300;
    const modelSize = type && type === 'download-file' && (await handleDownloadFileRequest(result));

    if (isSuccess) {
      requestsCacheManager.addToCache(requestUrl, result);
      timberLoggingProps = {
        url: requestUrl,
        options: modelSize
          ? { fileSize: `${modelSize}MB`, timeToDownloadMs, ...options, ...other }
          : { ...options, timeToDownloadMs },
        isForced: force,
        howRequestWasProcessed: 'Succeeded',
        module,
        biResultOptions: modelSize ? { fileSize: `${modelSize}MB`, timeToDownloadMs: timeToDownloadMs || '' } : {},
      };
      return result;
    } else {
      const { status } = result;
      const errMessage = 'failed to fetch - response status is bad';
      timberLoggingProps = {
        url: requestUrl,
        options: { ...options, timeToDownloadMs },
        isForced: force,
        errorMessage: `${errMessage}: ${status}`,
        howRequestWasProcessed: 'Failed',
        module,
      };
      return result;
    }
  } catch (err) {
    const stackTrace = { selector, url };
    const errorMessage = `error while fetching url: ${url || selector}. ${err}`;
    timberLoggingProps = {
      url: url || selector,
      options,
      isForced: force,
      howRequestWasProcessed: 'Failed',
      errorMessage: errorMessage,
      module,
    };
    return Promise.reject({ errorMessage, ...stackTrace });
  } finally {
    logToGAAndTimber(timberLoggingProps);
  }
};

const addHeaders = ({ headersObj = {}, headers = new Headers() }) => {
  Array.from(Object.entries(headersObj)).forEach(([key, value]) => {
    key && value && headers.append(key, value);
  });
  return headers;
};

const getScannerUrlByEnvironment = () => {
  const isStandalone = utils.isStandaloneMode();
  switch (true) {
    case isStandalone:
      return ScannerEnvironmentURLs.dev_host;
    case !isStandalone:
      return ScannerEnvironmentURLs.host;
    default:
      break;
  }
};

const compileUrl = ({ baseUrl, url, pathParams, queryParams }) => {
  const compiledUrl = `${template(url)(pathParams || {})}${encodeQueryParams(queryParams)}`;
  return `${baseUrl}/${compiledUrl}`;
};

const encodeQueryParams = (queryParams) => {
  if (!queryParams) {
    return '';
  }
  const ret = [];
  for (const paramKey in queryParams) {
    if (paramKey) {
      ret.push(`${encodeURIComponent(paramKey)}=${encodeURIComponent(queryParams[paramKey])}`);
    }
  }
  return `?${ret.join('&')}`;
};

const getBffUrl = () => {
  const isWeb3DToolBFFEnabled = featureAvaliability.getEnableWeb3DToolBFF();
  const { web3dViewerBFFEndPoint } = appSettingsManager.getAppSettingsByValue('environmentParametersSettings');
  const bffUrl = settingsManager.getConfigValue(configuration.serverEndpoint);
  const serverEndpoint = isWeb3DToolBFFEnabled ? web3dViewerBFFEndPoint : bffUrl;
  if (serverEndpoint) {
    return endsWith(serverEndpoint, '/') ? serverEndpoint.substr(0, serverEndpoint.length - 1) : serverEndpoint;
  }
};

const getScannerUrl = () => {
  return getScannerUrlByEnvironment();
};

const getEnvironmentParams = (selector) => {
  const isScannerEnv = utils.isScannerHostEnv();
  const { path, method } = selector ? apiMap(selector) : {};
  const baseUrl = selector && (() => (isScannerEnv ? getScannerUrl() : getBffUrl()))();
  const defaultOptions = {
    credentials: 'include',
    ...(isScannerEnv
      ? {
          headers: {
            'Content-Type': Content_Type,
          },
        }
      : {}),
  };

  return {
    baseUrl,
    url: path,
    defaultOptions,
    method,
  };
};

export { getEnvironmentParams, compileUrl, getBffUrl, getScannerUrl, handleRequest, logToGAAndTimber, addHeaders };
