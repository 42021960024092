import { useState, useCallback, useRef } from 'react';
import { invalidate } from 'react-three-fiber';
import { calcZoomToFitAll, getBoundingBox, getBoundingBoxCenter } from '@web-3d-tool/shared-logic';
import { logger } from '@web-3d-tool/shared-logic';

const DEFAULT_MODEL_ZOOM = 0.945;

export const useCamera = (position, up, zoomParameter = DEFAULT_MODEL_ZOOM) => {
  const [camera, setCamera] = useState();
  const [cameraControls, setCameraControl] = useState();
  const cRef = useRef();
  const ctrlRef = useRef();

  const cameraRef = useCallback((ref) => {
    if (ref !== null) {
      cRef.current = ref.current;
      setCamera(ref.current);
    }
  }, []);

  const cameraControlsRef = useCallback((ref) => {
    if (ref !== null) {
      ctrlRef.current = ref.current;
      setCameraControl(ref.current);
    }
  }, []);

  const setCameraTarget = (meshes) => {
    if (cRef.current && ctrlRef.current) {
      const meshesBoundingBox = getBoundingBox(meshes);
      const meshesCenter = getBoundingBoxCenter(meshesBoundingBox);

      cRef.current.lookAt(meshesCenter);
      ctrlRef.current.setCenterOfOrbit(meshesCenter);
    }
  };

  const setCameraZoom = (meshes, zoomPercentage) => {
    const meshesBoundingBox = getBoundingBox(meshes);
    let zoomToAllCanvas = calcZoomToFitAll(cRef.current, meshesBoundingBox);
    cRef.current.zoom = zoomPercentage * zoomToAllCanvas;
    cRef.current.updateProjectionMatrix();
  };

  const zoomCameraTo = useCallback(
    (meshes) => {
      const zoomPercentage = zoomParameter;
      try {
        if (meshes && cRef.current && zoomPercentage) {
          setCameraTarget(meshes);
          setCameraZoom(meshes, zoomPercentage);
          invalidate(true);
        }
      } catch (error) {
        logger
          .error('zoomCameraTo failed!')
          .to(['host'])
          .data({ module: 'camera-hook', zoomPercentage, cRef: cRef.current, error })
          .end();
      }
    },
    [zoomParameter]
  );

  const setStaticMode = useCallback((isStaticMode = false) => {
    if (ctrlRef.current) {
      ctrlRef.current.selectiveCancellation(isStaticMode);
      ctrlRef.current.updateCamera();
    }
  }, []);

  const resetCameraPosition = useCallback(
    (meshes, zoomPercentage = zoomParameter) => {
      if (cRef.current) {
        cRef.current.position.set(...position);
        cRef.current.up.set(...up);
        zoomCameraTo(meshes, zoomPercentage);
      }
    },
    [position, up, zoomCameraTo, zoomParameter]
  );

  return {
    camera,
    cameraControls,
    cameraRef,
    cameraControlsRef,
    resetCameraPosition,
    zoomCameraTo,
    setStaticMode,
  };
};
