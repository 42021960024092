export const Environments = {
  EUP: 'eup',
  SCANNER: 'scanner',
};

export const EnvironmentParams = {
  NIRI_ASSET_REFFERENCE_TYPE_NUMBER: '15',
};

export const ScannerEnvironmentURLs = {
  host: 'https://localhost:8080',
  dev_host: window.location.origin,
};

export const dentalXraiURLs = {
  getPatientRadiographsURL: `https://robgsktmlyqr6zk2h6arydkvy40opldx.lambda-url.us-west-2.on.aws/api/v1/orders/$orderId/result`,
};
