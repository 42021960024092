import React from 'react';
import PropTypes from 'prop-types';
import { POI } from '@web-3d-tool/shared-logic/src/constants/tools.constants';

export const Caries = ({ poiSprite, currentFlag }) => {
  poiSprite &&
    currentFlag &&
    poiSprite.forEach(({ children }) => {
      const seselectedPointOfInterest = children.find(({ name }) => name === 'selectedPointOfInterest');
      const pointOfInterest = children.find(({ name }) => name === 'pointOfInterest');
      seselectedPointOfInterest.visible =
        seselectedPointOfInterest.userData.flagId === currentFlag.selectFlagId &&
        seselectedPointOfInterest.userData.jaw === currentFlag.jaw;
      pointOfInterest.visible = !(
        pointOfInterest.userData.flagId === currentFlag.selectFlagId && pointOfInterest.userData.jaw === currentFlag.jaw
      );
    });

  return (
    <group name={POI.id}>
      {poiSprite &&
        poiSprite.map((flag) =>
          flag.children.map((sprite) => <sprite key={sprite.uuid} name="poiSprite" {...sprite} />)
        )}
    </group>
  );
};

Caries.propTypes = {
  /**
   * Mesh of flags
   */
  poiSprite: PropTypes.object,
  /**
   * Current selected flag
   */
  currentFlag: PropTypes.object,
};

export default Caries;
