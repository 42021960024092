import { Vector2, Vector3 } from 'three';
import { cloneDeep, get } from 'lodash';
import { cacheManager, cacheKeys } from '../cache-manager';
import { extractFile, getZippedObject } from '../unzip-util';
import { default as requestsManager } from '../api-requests';
import { default as logger } from '../logger';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
import {
  getExistedScansObj,
  getImageCenter,
  getImagesBlobsFromZippedData,
  getImagesMetaData,
  getPanoramaData,
} from './niri-manager.logic';
import { getClosestPhotoObjectRTH } from './default-scanner-type.logic';
import { getClosestPhotoObjectLumina } from './lumina-scanner-type.logic';
import { eventBus, globalEventsKeys } from '../event-bus';
import { isUpperJawEnable, isLowerJawEnable } from '../../../shared-logic/src/model-logic';
import { utils } from '../utils';
import { logToTimberBI, biMethods } from '../timberLogger';

export const isNiriEnabled = () => {
  const isIOCEnabled = settingsManager.getConfigValue(configuration.isIOCEnabled) === 'true';
  const isEVxEnabled = settingsManager.getConfigValue(configuration.isEVxEnabled) === 'true';
  return isEVxEnabled || isIOCEnabled;
};

export const getPhotosFileByEnv = async () => {
  let niriFilePath = requestsManager.getNiriFilePath();
  if (niriFilePath) {
    try {
      const progressCB = (percentage) =>
        eventBus.raiseEvent(globalEventsKeys.NIRI_PROGRESS_LOADING_CHANGED, percentage);
      const response = await requestsManager.getNiriFile(progressCB);
      const arraybufferData = await response.arrayBuffer();
      const zippedObject = await getZippedObject(arraybufferData);
      cacheManager.set(cacheKeys.ZIPPED_DATA, zippedObject);
      return zippedObject;
    } catch (err) {
      logger
        .error('error')
        .data({ module: 'niri-logic', err })
        .end();

      logToTimberBI(
        biMethods.errorReportBiLog({
          object: 'niri-logic',
          code: 'NIRI Loading Error',
          description: err.message,
          severity: 'Extracting of NIRI data - failed',
        })
      );
    }
  }
};

export const getMouseVector = async (x, y, z, camera, width = window.innerWidth, height = window.innerHeight) => {
  const p = new Vector3(x, y, z);
  const vector = p.project(camera);

  vector.x = ((vector.x + 1) / 2) * width;
  vector.y = (-(vector.y - 1) / 2) * height;

  return new Vector2(vector.x, vector.y);
};

export const preparePhotosData = async (zippedData) => {
  try {
    const promises = [
      await extractFile(zippedData, 'data.json', 'string'),
      await getImagesBlobsFromZippedData(zippedData),
    ];
    const [dataStr, _imagesBlobs] = await Promise.all(promises);
    const dataJson = JSON.parse(dataStr);
    cacheManager.set(cacheKeys.DATA_JSON, dataJson);

    const existedScans = getExistedScansObj(dataJson);
    const imagesCenter = getImageCenter(dataJson);
    const jawsPhotosMetadata = {};

    const scanToCamTx = get(dataJson, 'scan_to_cam_tx');
    const model = cacheManager.get(cacheKeys.MODEL);
    if (isUpperJawEnable(model.objects)) {
      jawsPhotosMetadata.upper_jaw = getImagesMetaData(dataJson, 'upper_jaw', existedScans, _imagesBlobs);
    }
    if (isLowerJawEnable(model.objects)) {
      jawsPhotosMetadata.lower_jaw = getImagesMetaData(dataJson, 'lower_jaw', existedScans, _imagesBlobs);
    }
    const panorama = getPanoramaData(dataJson, 'panorama', _imagesBlobs);
    logger.time(`extracting ${'data.json'} type ${'string'}`);
    return { existedScans, imagesCenter, jawsPhotosMetadata, panorama, scanToCamTx };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getVectorIntersects = async (pointerVector, rayCaster, camera, meshes) => {
  rayCaster.setFromCamera(pointerVector, camera);
  return rayCaster.intersectObjects(meshes);
};

export const getClosestPhotoObject = async ({
  jawName,
  intersect,
  imagesCenter,
  jawsPhotosMetadata,
  camera,
  rayCaster,
  eventOrigin,
  meshes,
}) => {
  return utils.getIsScanOriginLumina()
    ? getClosestPhotoObjectLumina({
        jawName,
        intersect,
        jawsPhotosMetadata,
        camera,
        rayCaster,
        eventOrigin,
        meshes,
      })
    : getClosestPhotoObjectRTH({ jawName, intersect, imagesCenter, jawsPhotosMetadata, camera });
};

export const getNormalizedLoupeCoords = async (
  centerOfLoupeCoords,
  containerWidth,
  containerHeight,
  splittedViewWithSidePluginActive,
  splittedWindowWidth
) => {
  const coordsClone = cloneDeep(centerOfLoupeCoords);
  if (!coordsClone) return null;
  if (splittedViewWithSidePluginActive && centerOfLoupeCoords.x > splittedWindowWidth)
    coordsClone.x -= splittedWindowWidth;
  const calculatedContainerWidth = splittedViewWithSidePluginActive ? splittedWindowWidth : containerWidth;
  const x = (coordsClone.x / calculatedContainerWidth) * 2 - 1;
  const y = -(coordsClone.y / containerHeight) * 2 + 1;

  return { x, y };
};
